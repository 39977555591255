import React, { forwardRef } from 'react';
import { styled } from 'styled-components';
import SendIcon from '../icons/ArrowRight.svg';

// Styled components for input wrapper and button
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  border: 1px solid #1f1f1f;
`;

const StyledInput = styled.input`
  flex: 1;
  height: 55px;
  padding-left: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 16px;

  /* Placeholder styling */
  &::placeholder {
    color: #4a4a4a;
    opacity: 1;
  }

  /* Autofill styles */
  &:-webkit-autofill {
    background-color: transparent !important; /* Override background */
    color: white !important; /* Ensure text remains white */
    -webkit-box-shadow: 0 0 0 1000px transparent inset; /* Remove browser's yellowish autofill background */
    transition: background-color 5000s ease-in-out 0s; /* Ensures no flashing effect */
  }

  &:-webkit-autofill::first-line {
    color: white !important; /* Ensures first line is also white in autofill */
  }
`;

const SendButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  img {
    width: 24px;
    height: 24px;
  }
`;

interface Props {
  placeholder: string;
  onClick: () => void;
  type?: string;
  autocomplete?: string;
  value: string;
  setValue: (e: string) => void;
  showArrow?: boolean;
}

const MailInput = forwardRef<HTMLInputElement, Props>(
  ({ showArrow = true, autocomplete, type = 'text', value, placeholder, onClick, setValue }, ref) => {
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onClick();
      }
    };

    return (
      <InputWrapper>
        <StyledInput
          ref={ref}
          onKeyUp={handleKeyPress}
          autoComplete={autocomplete}
          type={type}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder={placeholder}
          aria-label={placeholder}
        />

        <SendButton aria-label="send" hidden={!showArrow} onClick={onClick}>
          <img src={SendIcon} alt="send" />
        </SendButton>
      </InputWrapper>
    );
  }
);

export default MailInput;
