import { createTheme, alpha, getContrastRatio } from '@mui/material/styles';

const orangeBase = '#FC6600';
const orangeMain = alpha(orangeBase, 0.7);

const theme = createTheme({
  palette: {
    secondary: {
      main: orangeMain,
      light: alpha(orangeBase, 0.5),
      dark: alpha(orangeBase, 0.9),
      contrastText: getContrastRatio(orangeBase, '#fff') >= 3 ? '#fff' : '#000',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          border: '1px solid #fff',
        },
      },
    },
  },
});

export default theme;
